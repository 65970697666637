import React from 'react';
import moment from 'moment';
import CalendarEvent from '../CalendarEvent/CalendarEvent';
import CalendarGhost from '../CalendarGhost/CalendarGhost';
import './CalendarDay.scss';

const CalendarDay = ({ item, crmEvents, crmGhosts, setModalContent }) => {
  const siblingMod = !item.currentMonth ? `calendar_day-sibling` : '';
  const emptyMod = !crmEvents ? 'calendar--day-empty' : '';
  const todayMod =
    item.full === moment().format('YYYY-MM-DD') ? 'calendar_day-today' : '';

  const doSort = (items) => {
    return items.sort((a, b) =>
      a.date.start > b.date.start
        ? 1
        : a.date.start === b.date.start
        ? a.id > b.id
          ? 1
          : -1
        : -1
    );
  };

  const crmEventsSorted = crmEvents ? doSort(crmEvents) : null;
  const crmGhostsSorted = crmGhosts ? doSort(crmGhosts) : null;

  return (
    <div
      className={`calendar--day ${emptyMod} calendar_day calendar_day-${item.day} ${todayMod} ${siblingMod}`}
    >
      <div className="calendar_day--header">
        <div className="calendar_day--date">
          <span className="calendar_day--date_day">{item.day}</span>{' '}
          <span className="calendar_day--date_month">{item.monthRu}</span>
        </div>
        <div className="calendar_day--weekday">{item.weekdayRu}</div>
      </div>
      <div className="calendar_day--body">
        {crmEventsSorted &&
          crmEventsSorted.map((item) => (
            <CalendarEvent
              key={item.id}
              item={item}
              setModalContent={setModalContent}
            />
          ))}
        {crmGhostsSorted &&
          crmGhostsSorted.map((item) => (
            <CalendarGhost key={item.id} item={item} />
          ))}
      </div>
    </div>
  );
};

export default CalendarDay;
