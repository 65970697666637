import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Contact.scss';
import Settings from '../../utils/Settings';
import Functions from '../../utils/Functions';

const Contact = ({ block }) => {
  const [Tels, setTels] = useState(null);

  // TODO: replace with getRequestUrl()

  useEffect(() => {
    axios
      .get(`${Settings.requestUrl}/wp-json/wp/v2/pages/128`)
      .then((response) => {
        setTels([response.data.acf.tel_1, response.data.acf.tel_2]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={block ? `${block}--contact contact` : 'contact'}>
      {Tels &&
        Tels.map((item) => (
          <a
            key={item}
            href={`tel:${Functions.stripTel(item)}`}
            className="contact--tel"
          >
            {item}
          </a>
        ))}
    </div>
  );
};

export default Contact;
