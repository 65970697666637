import React from 'react';
import Functions from '../../utils/Functions';
import Settings from '../../utils/Settings';
import './CalendarGhost.scss';

const CalendarGhost = ({ item }) => {
  const categoryCrm = Settings.categoryCrm.find(
    (el) => el.slug === item.category
  );
  const color = categoryCrm ? categoryCrm.color : '#00475d';
  const modSecond = item.isSecondDay ? 'calendar_ghost-second' : '';
  const modLast = item.isLastDay ? 'calendar_ghost-last' : '';

  const handleGhostClick = (e) => {
    const id = e.target.getAttribute('data-event-id');
    const eventHeader = document.querySelector(`#event-${id} .js-event-header`);
    eventHeader.click();
  };

  return (
    <div
      className={`calendar_day--event calendar_ghost ${modLast} ${modSecond}`}
      style={{
        '--my-color-var': color,
        order: item.order,
      }}
      data-event-id={item.id}
      onMouseOver={(e) => Functions.setHoveredEvent(e, true)}
      onMouseLeave={(e) => Functions.setHoveredEvent(e, false)}
      onClick={(e) => handleGhostClick(e)}
    />
  );
};

export default CalendarGhost;
