import React from 'react';
import './CollapseTrigger.scss';

const CollapseTrigger = ({ height }) => {
  return (
    <button
      type="button"
      className={`calendar_event--trigger collapse_trigger ${
        height !== 0 && 'active'
      }`}
    >
      <span className="collapse_trigger--title">
        {height === 0 ? 'Подробнее' : 'Скрыть'}
      </span>
    </button>
  );
};

export default CollapseTrigger;
