import React from 'react';
import logo from '../../svg/logo-alt.svg';
import Settings from '../../utils/Settings';
import Backlink from '../Backlink/Backlink';
import Contact from '../Contact/Contact';
import './Header.scss';

const Header = () => {
  return (
    <header className="wrapper--header header">
      <div className="header--sidea">
        <Backlink modificator="header" />
        {/* TODO: replace with getRequestUrl() */}
        <a href={Settings.requestUrl} className="header--logo">
          <img src={logo} alt="Morclass Logo" className="header--logo_img" />
        </a>
        <h1 className="header--title">Расписание</h1>
      </div>
      <div className="header--sideb">
        {/* TODO: replace with getRequestUrl() */}
        <a
          href={`${Settings.requestUrl}/student/plavaniya-i-podgotovka-k-nim/`}
          className="header--link"
        >
          Подготовка к плаванию
        </a>
        <Contact block="header" />
      </div>
    </header>
  );
};

export default Header;
