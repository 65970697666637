import React from 'react';
import Settings from '../../utils/Settings';
import './Backlink.scss';

const Backlink = ({ block, modificator }) => {
  return (
    // TODO: replace with getRequestUrl()
    <a
      href={Settings.requestUrl}
      className={
        block
          ? `${block}--back back_link`
          : modificator
          ? `back_link back_link-${modificator}`
          : 'back_link'
      }
    >
      <i className="back_link--icon icon icon-arr-1-left"></i>
      <span className="back_link--title">На сайт</span>
    </a>
  );
};

export default Backlink;
