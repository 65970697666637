import React from 'react';
import Functions from '../../utils/Functions';
import './EventCapacity.scss';

const EventCapacity = ({ block, group, capacity }) => {
  let title = 'Группа сформирована';

  if (group.status_id === 1) {
    title =
      capacity > 0
        ? `Есть ${capacity} ${Functions.setWordEnding(capacity, 'мест')}`
        : 'Мест нет';
  }

  return <div className={`${block} event_capacity`}>{title}</div>;
};

export default EventCapacity;
