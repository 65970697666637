import React from 'react';
import moment from 'moment';
import './App.scss';
import './fonts/golos-text/stylesheet.css';
import './fonts/iconfont/stylesheet.css';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Calendar from './components/Calendar/Calendar';

function App() {
  const hashHandle = (hash) => {
    return moment(hash, '#YYYY-MM').isValid()
      ? moment(hash, '#YYYY-MM')
      : moment();
  };

  return (
    <div className="wrapper">
      <Header />
      <Calendar nowMoment={hashHandle(window.location.hash)} />
      <Footer />
    </div>
  );
}

export default App;
