import React from 'react';
import './LocationLink.scss';

const LocationLink = ({ block, location }) => {
  const url = `https://yandex.ru/maps/?ll=30.310182,59.951059&z=13&text=${location}`;

  return (
    <a
      href={url}
      target="_blank"
      rel="nofollow noreferrer"
      className={`${block}--location icon_link`}
    >
      <i className="icon_link--icon icon icon-location"></i>
      <span className="icon_link--title">{location}</span>
    </a>
  );
};

export default LocationLink;
