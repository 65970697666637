import React from 'react';
import { getRequestUrl } from '../../utils/Api';
import EventCapacity from '../EventCapacity/EventCapacity';
import OrderButton from '../OrderButton/OrderButton';
import './CalendarEventFooter.scss';

const CalendarEventFooter = ({ block, category, term, group, capacity }) => {
  let request =
    term === 'course'
      ? `/order/?category=course&course_slug=${category}`
      : `/order/?category=${category}`;

  if (group) request += `&crm_group_id=${group.id}`;

  const orderLink = getRequestUrl() + request;

  return (
    <div className={`${block} calendar_event_footer`}>
      {group && (
        <EventCapacity
          block="calendar_event_footer--capacity"
          group={group}
          capacity={capacity}
        />
      )}
      {orderLink && (
        <OrderButton orderLink={orderLink} group={group} capacity={capacity} />
      )}
    </div>
  );
};

export default CalendarEventFooter;
