import React from 'react';
import './Loader.scss';

const Loader = ({ block, modificator }) => {
  return (
    <div
      className={`${block ? `${block}--loader` : 'calendar--loader'} loader ${
        modificator ? `loader-${modificator}` : ''
      }`}
    />
  );
};

export default Loader;
