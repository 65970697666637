import axios from 'axios';
import Settings from './Settings';

const Functions = {
  // Get Token
  getCrmToken: async function () {
    const now = Date.now();
    let token = localStorage.getItem('CrmToken');
    let time = localStorage.getItem('CrmTokenExpireTime');
    // If it is actual token
    if (token && time && time > now) return token;
    // If not, get actual token
    return axios
      .post(`${Settings.requestCrmUrl}/v2api/auth/login`, {
        email: Settings.requestCrmEmail,
        api_key: Settings.requestCrmApiKey,
      })
      .then((response) => {
        const { token } = response.data;
        time = now + 15 * 60 * 1000; // 15 minutes
        localStorage.setItem('CrmToken', token);
        localStorage.setItem('CrmTokenExpireTime', time);
        return token;
      })
      .catch(() => {
        throw new Error('CRM token error');
      });
  },
  // Common snippets
  stripTel: (tel) => {
    return tel.replace(/\s|\(|\)|-/g, '');
  },
  stripTags: (html) => {
    return html.replace(/(<([^>]+)>)/gi, '');
  },
  formatDate: (datestring) => {
    const months = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];
    let date = new Date(datestring);
    let dd = date.getDate();
    let mm = months[date.getMonth()];
    let yy = date.getFullYear();

    if (dd < 10) dd = '0' + dd;
    if (yy < 10) yy = '0' + yy;

    return dd + ' ' + mm + ' ' + yy;
  },
  getFeaturedMedia: (featuredMedia) => {
    return {
      src: featuredMedia.media_details.sizes.full.source_url,
      title: featuredMedia.title.rendered,
      alt: featuredMedia.alt_text,
    };
  },
  setWordEnding: (number, word) => {
    let lastLetter = '';
    const lastNumber = Number(number.toString().slice(-1));
    if (number < 5 || number > 20) {
      if (lastNumber === 1) {
        lastLetter = 'о';
      } else if (lastNumber > 1 && lastNumber < 5) {
        lastLetter = 'а';
      }
    }

    return word + lastLetter;
  },
  switchMonth: (now, nextMonth) => {
    now.date = 1; // was "dates" key early =/
    now.dates = 1; // it is fix?
    if (nextMonth === true) {
      now.months++;
      if (now.months === 12) {
        now.years++;
        now.months = 0;
      }
    } else if (nextMonth === false) {
      now.months--;
      if (now.months === -1) {
        now.years--;
        now.months = 11;
      }
    } else {
      now.months = nextMonth;
    }
    return now;
  },
  isLongEvent: (categorySlug) => {
    const longEventCategory = ['hiking', 'regatta', 'charter'];
    return longEventCategory.indexOf(categorySlug) !== -1 ? true : false;
  },
  setHoveredEvent: (e, mouseIsOver) => {
    const id = e.target.getAttribute('data-event-id');
    const events = document.querySelectorAll(`[data-event-id="${id}"]`);
    events.forEach((element) => {
      if (mouseIsOver) {
        element.classList.add('hovered');
      } else {
        element.classList.remove('hovered');
      }
    });
  },
};

export default Functions;
