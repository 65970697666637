import React from 'react';
import Settings from '../../utils/Settings';
import Backlink from '../Backlink/Backlink';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="wrapper--footer footer">
      <div className="footer--block">
        <Backlink block="footer" />
      </div>
      <div className="footer--block footer--block-right footer--block-order_1">
        <a href="https://dki.design/" className="footer--dki dki">
          <i className="dki--logo icon icon-dki"></i>
          <span className="dki--title">Дизайн</span>
        </a>
      </div>
      <hr className="footer--hr" />
      <div className="footer--block">
        <p className="footer--text">
          © Санкт-Петербургские <br />
          Мореходные классы, {new Date().getFullYear()}
        </p>
      </div>
      <div className="footer--block footer--block-right footer--block-last">
        {/* TODO: replace with getRequestUrl() */}
        <a href={`${Settings.requestUrl}/privacy/`} className="footer--text">
          Политика конфиденциальности
        </a>
      </div>
    </footer>
  );
};

export default Footer;
