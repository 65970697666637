import React, { useState } from 'react';
import Settings from '../../utils/Settings';
import './NavCategory.scss';

const NavCategory = ({ setCategory, activeNav, setActiveNav }) => {
  const [Categories] = useState(Settings.categoryCrm);
  const [ActiveCategory, setActiveCategory] = useState(Categories[0]);
  const Active = activeNav === 'NavCategory' ? true : false;

  const buttonClickHandle = (slug) => {
    setCategory(slug);
    setActiveCategory(Categories.find((item) => item.slug === slug));
    setActiveNav('');
  };

  return (
    <div className={`nav--category nav_category ${Active ? 'active' : ''}`}>
      <button
        onClick={() => setActiveNav(!Active ? 'NavCategory' : '')}
        className="nav_category--trigger"
      >
        <span className="nav_category--trigger_title">
          {ActiveCategory.title}
        </span>
        <i
          className={`nav_category--trigger_icon icon icon-cal-${ActiveCategory.slug}`}
          style={{ color: ActiveCategory.color }}
        ></i>
      </button>
      <nav className={`nav_category--dropbox ${Active ? 'active' : ''}`}>
        <ul className="nav_category--list">
          {Categories &&
            Categories.map((item) => (
              <li key={item.slug} className="nav_category--item">
                <button
                  onClick={() => buttonClickHandle(item.slug)}
                  className={`nav_category--button ${
                    item.slug === ActiveCategory.slug ? 'active' : ''
                  }`}
                >
                  <i
                    className={`nav_category--button_icon icon icon-cal-${item.slug}`}
                    style={{ color: item.color }}
                  ></i>
                  <span className="nav_category--button_title">
                    {item.title}
                  </span>
                  <i className="nav_category--button_check icon icon-check"></i>
                </button>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default NavCategory;
