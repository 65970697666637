import React from 'react';
import './Warning.scss';

const Warning = ({ errorMessage, setErrorMessage }) => {
  return (
    <div className="warning">
      <i className="warning--logo icon icon-logo-sm"></i>
      <span className="warning--title">{errorMessage}</span>
      <button
        type="button"
        className="warning--close"
        onClick={() => setErrorMessage('')}
      >
        <i className="warning--close_icon icon icon-cross"></i>
      </button>
    </div>
  );
};

export default Warning;
