import axios from 'axios';
import Settings from './Settings';

export const getIsDevelopment = () => process.env.NODE_ENV === 'development';

export const getRequestUrl = () => {
  return getIsDevelopment() ? 'http://localhost:3000' : 'https://morclass.ru';
};

export const requestCrmData = async (token, endpoint, data) => {
  try {
    // Get first page
    const config = {
      url: `${Settings.requestCrmUrl}/v2api/1/${endpoint}/index`,
      method: 'POST',
      headers: {
        'X-ALFACRM-TOKEN': token,
      },
      data: {
        page: 0,
        ...data,
      },
    };
    const firstResponse = await axios(config);
    let { items, total, count } = firstResponse.data;
    // Get next pages
    const diff = total / count;
    if (diff > 1) {
      const iterations = Math.floor(diff);
      for (let index = 1; index <= iterations; index++) {
        config.data.page = index;
        const nextResponse = await axios(config);
        items = [...items, ...nextResponse.data.items];
      }
    }
    if (endpoint === 'lesson' && items.length === 0)
      throw new Error('Записей в этом месяце не найдено');
    return items;
  } catch (error) {
    throw new Error(error);
  }
};
