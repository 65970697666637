import React from 'react';
import './OrderButton.scss';

const OrderButton = ({ orderLink, group, capacity }) => {
  let is_disabled = group.status_id !== 1 || capacity === 0 ? true : false;

  return (
    <a
      href={orderLink}
      className={`calendar_event--button order_button ${
        is_disabled ? 'disabled' : ''
      }`}
    >
      <span className="order_button--title">
        {is_disabled ? 'Записи нет' : 'Записаться'}
      </span>
      <i className="order_button--icon icon icon-arr-1-right"></i>
    </a>
  );
};

export default OrderButton;
