import React, { useState } from 'react';
import moment from 'moment';
import Settings from '../../utils/Settings';
import Functions from '../../utils/Functions';
import './NavDate.scss';

const NavDate = ({ now, setNow, activeNav, setActiveNav }) => {
  const [Year, setYear] = useState(now.years);
  const Active = activeNav === 'NavDate' ? true : false;

  const handleSetNow = (value, year) => {
    let newNow = Functions.switchMonth(now, value);
    if (year) newNow.years = year;

    window.location.hash = moment(newNow).format('#YYYY-MM');
    setNow({ ...newNow });
    setActiveNav('');
  };

  const handleSetYear = (value) => {
    let year = value ? Year + 1 : Year - 1;
    setYear(year);
  };

  return (
    <div className={`nav--date nav_date ${Active ? 'active' : ''}`}>
      <div className="nav_date--header">
        <button
          onClick={() => handleSetNow(false)}
          className="nav_date--control"
        >
          <i className="icon icon-arr-1-left"></i>
        </button>
        <button
          onClick={() => setActiveNav(!Active ? 'NavDate' : '')}
          className="nav_date--trigger"
        >
          <i className="nav_date--trigger_icon icon icon-cal-all-date"></i>
          <span className="nav_date--trigger_title">
            {Settings.months[now.months].title} {now.years}
          </span>
        </button>
        <button
          onClick={() => handleSetNow(true)}
          className="nav_date--control"
        >
          <i className="icon icon-arr-1-right"></i>
        </button>
      </div>
      <div className={`nav_date--dropbox ${Active ? 'active' : ''}`}>
        <div className="nav_date--wrap">
          <div className="nav_date--year">
            <button
              onClick={() => handleSetYear(false)}
              className="nav_date--year_control"
            >
              <i className="icon icon-arr-1-left"></i>
            </button>
            <div className="nav_date--year_title">{Year}</div>
            <button
              onClick={() => handleSetYear(true)}
              className="nav_date--year_control"
            >
              <i className="icon icon-arr-1-right"></i>
            </button>
          </div>
          <ul className="nav_date--list">
            {Settings.months &&
              Settings.months.map((item) => (
                <li key={item.slug} className="nav_date--item">
                  <button
                    onClick={() => handleSetNow(item.id, Year)}
                    className={`nav_date--button ${
                      Settings.months[now.months].slug === item.slug &&
                      now.years === Year
                        ? 'active'
                        : ''
                    }`}
                  >
                    {item.title}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavDate;
