import React, { useCallback, useEffect } from 'react';
import Settings from '../../utils/Settings';
import CalendarEventContent from '../CalendarEventContent/CalendarEventContent';
import CalendarEventFooter from '../CalendarEventFooter/CalendarEventFooter';
import LocationLink from '../LocationLink/LocationLink';
import './CalendarModal.scss';

const Modal = ({ modalContent, setModalContent }) => {
  // Esc key handle
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        setModalContent(null);
      }
    },
    [setModalContent]
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const event = Settings.categoryCrm.find(
    (item) => item.slug === modalContent.category
  );

  const eventTerm = event?.term ?? '';
  const eventColor = event?.color ?? '';

  return (
    <div
      className="calendar--modal calendar_modal"
      onClick={() => setModalContent(null)}
    >
      <div
        className="calendar_modal--inner"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="calendar_modal--params">
          <div
            className="calendar_modal--params_item"
            style={
              Settings.term[eventTerm]
                ? { '--my-color-var': Settings.term[eventTerm].color }
                : undefined
            }
          >
            {Settings.term[eventTerm]?.title}
          </div>
          <div className="calendar_modal--params_item calendar_modal--params_item-date">
            {modalContent.time}
          </div>
        </div>
        <div className="calendar_modal--header">
          <i
            className={`calendar_modal--header_icon icon icon-cal-${modalContent.category}`}
            style={{ color: eventColor }}
          ></i>
          <span className="calendar_modal--header_title">
            {modalContent.title}
            <br />
            {modalContent.note}
          </span>
        </div>
        <div className="calendar_modal--body">
          {modalContent.description && (
            <CalendarEventContent
              block="calendar_modal"
              content={modalContent.description}
            />
          )}
          {modalContent.location && (
            <LocationLink
              block="calendar_modal"
              location={modalContent.location}
            />
          )}
        </div>
        {modalContent.group && (
          <CalendarEventFooter
            block="calendar_modal--footer"
            category={modalContent.category}
            term={modalContent.term}
            group={modalContent.group}
            capacity={modalContent.capacity}
          />
        )}
        <button
          type="button"
          onClick={() => setModalContent(null)}
          className="calendar_modal--close"
        >
          <i className="calendar_modal--close_icon icon icon-cross"></i>
        </button>
      </div>
    </div>
  );
};

export default Modal;
