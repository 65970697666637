import React from 'react';
import './CalendarEventContent.scss';

const CalendarEventContent = ({ block, content }) => {
  const htmlContent = content.replace(/\r\n/g, '<br/>');

  return (
    <div
      className={`${block}--content event_content`}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default CalendarEventContent;
