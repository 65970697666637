// Common settings

const Settings = {
  requestUrl: 'https://morclass.ru',
  requestCrmUrl: 'https://anodpospbmorekhodnyeklassy.s20.online',
  requestCrmEmail: 'mail@morclass.ru',
  requestCrmApiKey: 'f832b02d-0fab-11ec-b74c-ac1f6b4782be',
  weekdays: [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ],
  months: [
    {
      id: 0,
      slug: 'january',
      title: 'Январь',
      titleRu: 'января',
    },
    {
      id: 1,
      slug: 'february',
      title: 'Февраль',
      titleRu: 'февраля',
    },
    {
      id: 2,
      slug: 'march',
      title: 'Март',
      titleRu: 'марта',
    },
    {
      id: 3,
      slug: 'april',
      title: 'Апрель',
      titleRu: 'апреля',
    },
    {
      id: 4,
      slug: 'may',
      title: 'Май',
      titleRu: 'мая',
    },
    {
      id: 5,
      slug: 'june',
      title: 'Июнь',
      titleRu: 'июня',
    },
    {
      id: 6,
      slug: 'july',
      title: 'Июль',
      titleRu: 'июля',
    },
    {
      id: 7,
      slug: 'august',
      title: 'Август',
      titleRu: 'августа',
    },
    {
      id: 8,
      slug: 'september',
      title: 'Сентябрь',
      titleRu: 'сентября',
    },
    {
      id: 9,
      slug: 'october',
      title: 'Октябрь',
      titleRu: 'октября',
    },
    {
      id: 10,
      slug: 'november',
      title: 'Ноябрь',
      titleRu: 'ноября',
    },
    {
      id: 11,
      slug: 'december',
      title: 'Декабрь',
      titleRu: 'декабря',
    },
  ],
  term: {
    // TODO: Refactor to array. Get from WP?
    course: {
      title: 'Курс',
      slug: 'Course',
      color: '#2096B5',
    },
    practice: {
      title: 'Практика',
      slug: 'practice',
      color: '#616DD8',
    },
    'open-lecture': {
      title: 'Курс',
      slug: 'Course',
      color: '#2096B5',
    },
    'day-sailing': {
      title: 'Дневное плавание',
      slug: 'day-sailing',
      color: '#A099D4',
    },
    hiking: {
      title: 'Поход',
      slug: 'hiking',
      color: '#2AC6E6',
    },
    regatta: {
      title: 'Регата',
      slug: 'regatta',
      color: '#FF75D5',
    },
    testing: {
      title: 'Тестирование',
      slug: 'testing',
      color: '#00355D',
    },
    event: {
      title: 'Мероприятие',
      slug: 'event',
      color: '#C2B47E',
    },
    charter: {
      title: 'Чартер',
      slug: 'charter',
      color: '#E9C63D',
    },
  },
  categoryCrm: [
    {
      id: 0,
      title: 'Все расписание',
      slug: 'all-event',
      color: '#00475d',
      term: 'empty',
    },
    {
      id: 23,
      title: 'Яхтенный матрос',
      slug: 'competent-crew',
      color: '#4CD6D6',
      term: 'course',
    },
    {
      id: 37,
      title: 'Судоводитель ГИМС. Парус',
      slug: 'bareboat-skipper',
      color: '#2096B5',
      term: 'course',
    },
    {
      id: 1,
      title: 'Яхтенный шкипер',
      slug: 'coastal-skipper',
      color: '#00475D',
      term: 'course',
    },
    {
      id: 7,
      title: 'Яхтенный капитан',
      slug: 'yachtmaster-offshore',
      color: '#202833',
      term: 'course',
    },
    {
      id: 9,
      title: 'Капитан моторного судна. ГИМС',
      slug: 'motor-vessel',
      color: '#45A735',
      term: 'course',
    },
    {
      id: 36,
      title: 'Судоводитель аэролодки',
      slug: 'airboat-navigator',
      color: '#45A735',
      term: 'course',
    },
    {
      id: 11,
      title: 'Штурманское дело',
      slug: 'navigation',
      color: '#35A78C',
      term: 'course',
    },
    {
      id: 12,
      title: 'Радиооператор ГМССБ',
      slug: 'gmdss-short-range-certificate',
      color: '#35A78C',
      term: 'course',
    },
    {
      id: 14,
      title: 'Открытые лекции и семинары', // ?
      slug: 'open-lecture',
      color: '#FE8000',
      term: 'open-lecture',
    },
    {
      id: 24,
      title: 'Яхтенный английский',
      slug: 'sailing-english',
      color: '#32A78C',
      term: 'course',
    },
    {
      id: 31,
      title: 'Мореходная астрономия',
      slug: 'nautical-astronomy',
      color: '#FE8000',
      term: 'course',
    },
    {
      id: 38,
      title: 'Такелажное дело',
      slug: 'rigging',
      color: '#FE8000',
      term: 'course',
    },
    {
      id: 34,
      title: 'Использование РЛС',
      slug: 'rls',
      color: '#32A78C',
      term: 'course',
    },
    {
      id: 32,
      title: 'Введение в яхтинг',
      slug: 'introduction-to-yachting',
      color: '#4CD6D6',
      term: 'course',
    },
    {
      id: 18,
      title: 'Морской поход',
      slug: 'hiking',
      color: '#2AC6E6',
      term: 'hiking',
    },
    {
      id: 17,
      title: 'Квалификационное плавание',
      slug: 'qualifying-sailing',
      color: '#A099D4',
      term: 'practice',
    },
    {
      id: 22,
      title: 'Чартерные плавания',
      slug: 'charter',
      color: '#E9C63D',
      term: 'charter',
    },
    {
      id: 19,
      title: 'Регаты и фестивали',
      slug: 'regatta',
      color: '#FF75D5',
      term: 'regatta',
    },
    {
      id: 40,
      title: 'Мастер-классы',
      slug: 'master-class',
      color: '#616DD8',
      term: 'practice',
    },
    {
      id: 20,
      title: 'Зачеты и экзамены',
      slug: 'testing',
      color: '#00475D',
      term: 'testing',
    },
    {
      id: 21,
      title: 'Мероприятия',
      slug: 'event',
      color: '#C2B47E',
      term: 'event',
    },
  ],
};

export default Settings;
