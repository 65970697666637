import React, { useState } from 'react';
import NavCategory from '../NavCategory/NavCategory';
import NavDate from '../NavDate/NavDate';
import './Nav.scss';

const Nav = ({ setCategory, now, setNow }) => {
  const [ActiveNav, setActiveNav] = useState('');

  return (
    <nav className="calendar--nav nav">
      <NavDate
        now={now}
        setNow={setNow}
        activeNav={ActiveNav}
        setActiveNav={setActiveNav}
      />
      <NavCategory
        setCategory={setCategory}
        activeNav={ActiveNav}
        setActiveNav={setActiveNav}
      />
    </nav>
  );
};

export default Nav;
