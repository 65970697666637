import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import AnimateHeight from 'react-animate-height';
import LocationLink from '../LocationLink/LocationLink';
import Settings from '../../utils/Settings';
import CalendarEventContent from '../CalendarEventContent/CalendarEventContent';
import CollapseTrigger from '../CollapseTrigger/CollapseTrigger';
import './CalendarEvent.scss';
import CalendarEventFooter from '../CalendarEventFooter/CalendarEventFooter';
import axios from 'axios';
import Loader from '../Loader/Loader';
import Functions from '../../utils/Functions';

const moment = extendMoment(Moment);

const CalendarEvent = ({ item, setModalContent }) => {
  const [Loading, setLoading] = useState(false);
  const [Active, setActive] = useState(false);
  const [Height, setHeight] = useState(0);
  const [Capacity, setCapacity] = useState(null);

  // Event time handle
  const { start, end, duration } = item.date;
  let time = moment(start).format('HH:mm');
  const endFormat = duration > 1 ? 'D MMMM' : 'HH:mm';
  const endString = moment(end).format(endFormat);
  time += duration > 1 ? `, до ${endString}` : ` – ${endString}`;

  const categoryCrm = Settings.categoryCrm.find(
    (el) => el.slug === item.category
  );
  const color = categoryCrm ? categoryCrm.color : '#00475d';

  const getGroupTotalMembers = async () => {
    try {
      const token = await Functions.getCrmToken();
      const response = await axios({
        url: `${Settings.requestCrmUrl}/v2api/1/cgi/index`,
        method: 'POST',
        headers: {
          'X-ALFACRM-TOKEN': token,
        },
        params: {
          group_id: item.group.id,
        },
      });
      return response.data.total;
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleEventClick = async () => {
    if (Capacity === null && item.group) {
      setLoading(true);
      const groupTotalMembers = await getGroupTotalMembers();
      setCapacity(item.group.limit - groupTotalMembers);
      setLoading(false);
      setActive(true);
      setModalContent({
        ...item,
        time,
        capacity: item.group.limit - groupTotalMembers,
      });
    } else {
      setActive(!Active);
      setModalContent({ ...item, time, capacity: Capacity });
    }
  };

  useEffect(() => {
    setHeight(Active ? 'auto' : 0);
  }, [Active]);

  return (
    <div
      className={`calendar_day--event calendar_event ${
        Loading ? 'loading' : ''
      }`}
      style={{ '--my-color-var': color, order: item.order }}
      id={`event-${item.id}`}
      data-event-id={item.id}
      onMouseOver={(e) => Functions.setHoveredEvent(e, true)}
      onMouseLeave={(e) => Functions.setHoveredEvent(e, false)}
    >
      {Loading && <Loader block="calendar_event" modificator="sm" />}
      <div
        className="calendar_event--header js-event-header"
        onClick={() => handleEventClick()}
      >
        <i
          className={`calendar_event--icon icon icon-cal-${item.category}`}
          style={{ color: color }}
        />
        <div className="calendar_event--info">
          <div className="calendar_event--time">{time}</div>
          <div className="calendar_event--title">
            {item.title}
            <br />
            {item.note}
          </div>
        </div>
        <CollapseTrigger block="calendar_event" height={Height} />
      </div>
      <AnimateHeight
        className="calendar_event--body"
        duration={300}
        height={Height}
      >
        {item.description && (
          <CalendarEventContent
            block="calendar_event"
            content={item.description}
          />
        )}
        {item.location && (
          <LocationLink block="calendar_event" location={item.location} />
        )}
        {item.group && (
          <CalendarEventFooter
            block="calendar_event--footer"
            category={item.category}
            term={item.term}
            group={item.group}
            capacity={Capacity}
          />
        )}
      </AnimateHeight>
    </div>
  );
};

export default CalendarEvent;
